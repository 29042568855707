import React from 'react'
import styled from "styled-components"
import { GlobalStyle } from "../components/styles/GlobalStyles"
import Seo from "../components/Seo"
import BlogHeader from "../components/BlogHeader" 
import Footer from "../components/Footer"
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'


const BlogTemplate = ({ data }) => {
  const {mainTitle, richText, image, subContent, createdAt} = data.contentfulBlog
  const pathToImage = getImage(image)

  const options = {
    renderNode: {
      renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
      renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1>{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p>{children}</p>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <UlList>{children}</UlList>
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <OlList>{children}</OlList>
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <blockquote>{children}</blockquote>
      },
    }
  };

  return (
    <>
      <GlobalStyle />
      <Seo title="NEWS" />
      <BlogHeader />
      <BlogWrapper>
        <Blog>
          <BlogContent>
            <BlogTtl>
              <BlogH1>{ mainTitle }</BlogH1>
            </BlogTtl>
            <BlogDate>{ createdAt }</BlogDate>
            <BlogText>{renderRichText(richText, options)}</BlogText>
              {pathToImage ? (
              <BlogImg>
                <GatsbyImage 
                  image={pathToImage} 
                  style={{ height: "100%", width: "100%" }}
                  imgStyle={{ objectFit: "cover" }}
                  alt="image" 
                />
              </BlogImg>
              ) : (
                <p></p>
              )}  
            <BlogText>{renderRichText(subContent, options)}</BlogText>
          </BlogContent>
        </Blog>
      </BlogWrapper>
      <Footer />
    </>
  )
}

export const query = graphql`
  query getSingleBlog($title: String) {
    contentfulBlog(title: {eq: $title}) {
      title
      mainTitle
      richText {
        raw
      }
      image {
        gatsbyImageData
      }
      subContent {
        raw
      }
      createdAt(formatString: "YYYY年MM月DD日")
    }
  }
`

const BlogWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto 7rem;
  padding: 80px 16px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  letter-spacing: 1px;
`
const BlogImg = styled.div`
  margin: 2rem auto;
  width: 100%;
  height: 500px;
  @media screen and (max-width: 768px) {
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    height: 250px;
  }
`
const BlogTtl = styled.div``

const BlogH1 = styled.h1`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 30px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem 0;
  @media screen and (max-width: 992px) {
    font-size: 26px;
    line-height: 40px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 36px;
  }
`
const Blog = styled.div`
  width: 100%;
  text-decoration: none;
  color: #191919;
  padding: 20px 0;
`
const BlogContent = styled.div``

const BlogText = styled.div`
  font-family: 'Noto Sans JP', sans-serif;
  margin: 2rem 0;
  font-size: 18px;
  line-height: 36px;
  h1, h2, h3, p {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 33px;
  }
`
const BlogDate = styled.label`
  display: block;
  font-size: 14px;
`
const UlList = styled.ul`
  padding-left: 1.5rem;
`
const OlList = styled.ol`
  padding-left: 1.5rem;
`

export default BlogTemplate

